
    export default {
        components: {
            HeadingSection: () => import('../components/MainComponent/HeadingSection.vue'),
            TrustedBySection: () => import('../components/HomePage/TrustedBySection.vue'),
            ServicesSection: () => import('../components/HomePage/ServicesSection.vue'),
            OurWorkSection: () => import('../components/HomePage/OurWorkSection.vue'),
            TechnologySection: () => import('../components/HomePage/TechnologySection.vue'),
            ProjectsSection: () => import('../components/MainComponent/ProjectsSection.vue'),
        },
        computed: {
            topBannerData() {
                return this.$store.state.topBannerData;
            },
        },
        async asyncData(context) {
            await context.app.$getDataFromCache(`/wp-json/wp/v2/pages/7`)
                .then((response) => {
                    context.store.dispatch("setTopBannerData", response.data.acf);
                });
            await context.app.$getDataFromCache(`/wp-json/wp/v2/trusted_by`)
                .then((response) => {
                    context.store.dispatch("setTrustedByData", response.data);
                });
            await context.app.$getDataFromCache(`/wp-json/wp/v2/our_works?lang=lt`)
                .then((response) => {
                    context.store.dispatch("setOurWorkData", response.data);
                });
            await context.app.$getDataFromCache(`/wp-json/wp/v2/technologies?per_page=11`)
                .then((response) => {
                    context.store.dispatch("setTechnologyData", response.data);
                });
            await context.store.dispatch("setPageChangeAnimationValueFalse");
        },
    };
